.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #e6225d;
  transition: background-color 1s ease;
}

::-webkit-scrollbar {display:none;}

.slideLeft {
  transform: translate(-100%, 0);
  opacity: 0;
}

.box {
  transition-duration: .5s;
  transition-property: transform, opacity;
}

/* Prevent the a tag from coloring blue */
a { color: inherit; } 

.block-1-color {
  /* background-color: #7ac890; */
  background-color: #1F3A3D;
}

.block-2-color {
  background-color: #DC9D00;
}

.block-3-color {
  background-color: #0E294B;
}

.block-4-color {
  background-color: #C35831;
}